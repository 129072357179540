import React from "react";
import { isNumber } from "lodash";

import {
  irisInstrumentPropertyList,
  irisParticipantsPropertyList,
  irisSettingsPropertyList,
  oasisSummaryPropertyList,
  oasisParticipantsPropertyList,
  oasisResearchPropertyList,
  oasisSettingsPropertyList,
} from "../types/UIElements";
import { getProjectName } from "../lib/functions";

interface CheckInputDisabledStatusProps {
  materialSettings: object;
  doiField: boolean;
  fieldName: string;
  text?: string;
  adminText?: string;
}

interface CommonDisabledHelperResponse {
  disabled: boolean;
  noteText: any;
  text?: string;
  adminText?: string;
}

export interface GetFieldValuesProps {
  referenceNumber?: number;
  acknowledgementNumber?: number;
  name: string;
  submissionState: any;
  projectName: string;
}

export interface GetOnChangeHandler {
  referenceNumber?: number;
  acknowledgementNumber?: number;
  name: string;
  projectName: string;
}

const texts: any = {
  IRIS: {
    defaultText: `This material has been published and the DOI has been created on DataCite.<br />This field cannot be changed by you. If you wish to ask for any changes, please contact IRIS admin at <a href="mailto:iris@iris-database.org">iris@iris-database.org</a> who will consider your request.`,
    defaultAdminText: `This material has been published and the DOI has been created on DataCite.<br />Only you the ADMIN can make changes to this field.`,
  },
  OASIS: {
    defaultText: `This material has been published.<br />This field cannot be changed by you. If you wish to ask for any changes, please contact the OASIS team at <a href="mailto:oasis@oasis-database.org">oasis@oasis-database.org</a> who will consider your request.`,
    defaultAdminText: `This material has been published.<br />Only you, the ADMIN, can make changes to this field.`,
  },
};

const makeNoteBox = (text: string) => {
  return (
    <div className="flex w-full   p-2 mb-2 border-2 border-orange rounded">
      <div className="flex-initial">
        <span className="block" style={{ fontSize: `3em`, paddingTop: `7px` }}>
          ⚠️
        </span>
      </div>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default {
  commonDisabledHelper(
    props: CheckInputDisabledStatusProps
  ): CommonDisabledHelperResponse {
    const response: any = {
      disabled: false,
      noteText: "",
    };

    const projectName = getProjectName(true);

    const { materialSettings, doiField, text, adminText, fieldName } = props;

    if (materialSettings !== undefined && doiField) {
      if (materialSettings.status === "published") {
        const { defaultText } = texts[projectName];
        const { defaultAdminText } = texts[projectName];

        response.disabled = true;
        response.noteText = makeNoteBox(text || defaultText);

        if (materialSettings.isAdminUser) {
          response.disabled = false;
          response.noteText = makeNoteBox(adminText || defaultAdminText);
        }

        // Overwrite the `disabled` state for `publicationIdentifier` on `OASIS`
        // if (fieldName === "publicationIdentifier" && projectName === "OASIS") {
        //   response.disabled = false;
        // }
      }
    }

    return response;
  },

  checkInputDisabledStatus(props: CheckInputDisabledStatusProps): boolean {
    const check = this.commonDisabledHelper(props);
    return check.disabled;
  },
  getInputDisabledNote(props: CheckInputDisabledStatusProps): any {
    const check = this.commonDisabledHelper(props);
    return check.noteText;
  },
  /**
   * Return the field values for a given field
   */
  getFieldValues(props: GetFieldValuesProps) {
    // Extract from `props`
    const {
      referenceNumber,
      acknowledgementNumber,
      name,
      submissionState,
      projectName,
    } = props;

    // Extract from `submissionState`
    const {
      publication,
      acknowledgement,
      participant,
      instrument,
      settings,
      summary,
      research,
    } = submissionState;

    if (!projectName) {
      return "`projectName` is missing.";
    }

    const getFieldArray = (obj, key) => {
      if (!obj || !obj[key]) return [""];
      return Array.isArray(obj[key]) && obj[key].length > 0
        ? obj[key]
        : [obj[key]];
    };

    const projectPropertyLists: any = {
      IRIS: {
        participants: irisParticipantsPropertyList,
        instrument: irisInstrumentPropertyList,
        settings: irisSettingsPropertyList,
      },
      OASIS: {
        summary: oasisSummaryPropertyList,
        participants: oasisParticipantsPropertyList,
        settings: oasisSettingsPropertyList,
        research: oasisResearchPropertyList,
      },
    };

    const currentProjectLists = projectPropertyLists[projectName] || {};

    if (isNumber(referenceNumber)) {
      return getFieldArray(publication[referenceNumber], name);
    }

    if (isNumber(acknowledgementNumber)) {
      return getFieldArray(
        acknowledgement[acknowledgementNumber]?.publication,
        name
      );
    }

    if (currentProjectLists.participants?.includes(name)) {
      return getFieldArray(participant, name);
    }

    if (currentProjectLists.instrument?.includes(name)) {
      return getFieldArray(instrument, name);
    }

    if (currentProjectLists.settings?.includes(name)) {
      return getFieldArray(settings, name);
    }

    if (currentProjectLists.summary?.includes(name)) {
      return getFieldArray(summary, name);
    }

    if (currentProjectLists.research?.includes(name)) {
      return getFieldArray(research, name);
    }

    return [submissionState[name] || ""];
  },

  /**
   * Detect which handler to use
   */
  getOnChangeHandler(props: GetOnChangeHandler) {
    // Extract from `props`
    const { referenceNumber, acknowledgementNumber, name, projectName } = props;

    if (isNumber(referenceNumber)) {
      return "publicationChangeHandler";
    }

    if (isNumber(acknowledgementNumber)) {
      return "acknowledgementChangeHandler";
    }

    // IRIS & Instrument
    if (projectName === "IRIS" && irisInstrumentPropertyList.includes(name)) {
      return "instrumentChangeHandler";
    }

    // IRIS & Participant
    if (projectName === "IRIS" && irisParticipantsPropertyList.includes(name)) {
      return "participantChangeHandler";
    }

    // IRIS & Settings
    if (projectName === "IRIS" && irisSettingsPropertyList.includes(name)) {
      return "settingsChangeHandler";
    }

    // OASIS & Summary
    if (projectName === "OASIS" && oasisSummaryPropertyList.includes(name)) {
      return "summaryChangeHandler";
    }

    // OASIS & Participant
    // eslint-disable-next-line prettier/prettier
    if (projectName === "OASIS" && oasisParticipantsPropertyList.includes(name)) {
      return "participantChangeHandler";
    }

    // OASIS & Participant
    // eslint-disable-next-line prettier/prettier
    if (projectName === "OASIS" && oasisResearchPropertyList.includes(name)) {
      return "researchChangeHandler";
    }

    // OASIS & Settings
    if (projectName === "OASIS" && oasisSettingsPropertyList.includes(name)) {
      return "settingsChangeHandler";
    }

    return "inputChangeHandler";
  },
};
