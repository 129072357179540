import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { graphql, useStaticQuery, Link } from "gatsby";
import { PDFViewer } from "@react-pdf/renderer";
import MyDocument from "../../../../helpers/pdf/pdfDocument";
import LoadingIcon from "../../../loadingIcon";
import { DictionaryDataState } from "../../../../context/DictionaryDataContext";
import { SubmissionState } from "../../../../context/SubmissionContext";

interface StepProps {
  submissionStatus: any;
  missingRequiredFields: any;
  materialID: string;
  setOasisPdfPages: any;
}

export default function OasisSubmissionStep(props: StepProps) {
  const {
    missingRequiredFields,
    submissionStatus,
    materialID,
    setOasisPdfPages,
  } = props;

  const dictionaryDataState: any = useContext(DictionaryDataState) || [];
  const submissionState: any = useContext(SubmissionState) || "";

  const [loaded, setLoaded] = useState<boolean>(true);
  const [metadata, setMetadata] = useState<any>({});
  const [materialTitle, setMaterialTitle] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [warning, setWarning] = useState("");

  const steps: any = {
    summary: 1,
    publication: 2,
    research: 3,
    upload: 4,
    participant: 5,
    settings: 6,
  };

  useEffect(() => {
    if (submissionState) {
      setMetadata({
        materialMetadata: {
          summary: submissionState.summary,
          publication: submissionState.publication,
          research: submissionState.research,
          participant: submissionState.participant,
          settings: submissionState.settings,
        },
        objectMetadata: submissionState.objectMetadata,
      });
    }
  }, [submissionState]);

  useEffect(() => {
    setOasisPdfPages(totalPages || 1);
  }, [totalPages]);

  useEffect(() => {
    if (totalPages > 1) {
      setWarning(
        "Attention: The OASIS summary can only be 1 page long. Please revise the text entered to make sure the OASIS summary is only 1 page long."
      );
    } else {
      setWarning("");
    }

    setOasisPdfPages(totalPages);

    if (
      submissionState.pdfPages &&
      totalPages &&
      totalPages !== submissionState.pdfPages
    ) {
      console.log("Total pages 1: ", submissionState.pdfPages);
      console.log("Total pages 2: ", totalPages);
      submissionDispatch({
        type: "updatePDFpages",
        value: totalPages,
      });
    }
  }, [totalPages]);

  const missingFields = missingRequiredFields.map((item: any) => {
    if (item.message) {
      return <li key={uuidv4()}>{item.message}</li>;
    }
    const inStep = steps[item.partOf];

    return (
      <li key={uuidv4()}>
        Please enter a `{item.displayName}` (
        <Link to={`/submit/${materialID}/${inStep}`}>see step {inStep}</Link>)
      </li>
    );
  });

  /**
   * In order to make this work during the build we need to use this fix:
   * https://stackoverflow.com/questions/76007339/server-error-error-pdfdownloadlink-is-a-web-specific-api-youre-either-using-t
   */
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const {
    submittonText,
    oasisFooterText,
    oasisHeadingsWhatIsResearchAbout,
    oasisHeadingsWhatReseachersDid,
    oasisHeadingsWhatTheResearchersFound,
    oasisHeadingsThingsToConsider,
  } = useStaticQuery(graphql`
    query {
      submittonText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-submission-step-7" } }
      ) {
        html
        frontmatter {
          submittingText
          submittedText
          errorText
          missingFieldsText
          summaryOkayText
        }
      }

      oasisFooterText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-pdf-footer" } }
      ) {
        frontmatter {
          footer_line_1
          footer_line_2
        }
      }

      oasisHeadingsWhatIsResearchAbout: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-pdf-headings-1-what-is-research-about" }
        }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }

      oasisHeadingsWhatReseachersDid: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-pdf-headings-2-what-the-reseachers-did" }
        }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }

      oasisHeadingsWhatTheResearchersFound: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-pdf-headings-3-what-the-reseachers-found" }
        }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }

      oasisHeadingsThingsToConsider: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-pdf-headings-4-things-to-consider" }
        }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }
    }
  `);

  return (
    <>
      {submittonText.html ? (
        <div className="pb-5 mb-5 border-b border-b-gray-2">
          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: submittonText.html,
            }}
          />
        </div>
      ) : null}

      {missingFields.length === 0 && submissionStatus === "" ? (
        <div className="text-lg pb-5">
          {submittonText.frontmatter.summaryOkayText}
        </div>
      ) : null}

      {submissionStatus === "submitting" ? (
        <div className="text-lg pb-5">
          {submittonText.frontmatter.submittingText}
        </div>
      ) : null}

      {submissionStatus === "submitted" ? (
        <div className="text-lg pb-5">
          {submittonText.frontmatter.submittedText}
        </div>
      ) : null}

      {submissionStatus === "error" ? (
        <div className="text-lg pb-5">
          {submittonText.frontmatter.errorText}
        </div>
      ) : null}

      {missingFields.length > 0 ? (
        <div style={{ marginBottom: `15px` }}>
          {submittonText.frontmatter.missingFieldsText}
          <br />
          <ul style={{ listStyle: `inside` }}>{missingFields}</ul>
        </div>
      ) : null}

      {isClient &&
      loaded &&
      metadata &&
      metadata.materialMetadata &&
      metadata.objectMetadata &&
      dictionaryDataState.oasisAuthors ? (
        <div style={{ marginBottom: `15px` }}>
          <PDFViewer width="100%" height="1100px" showToolbar>
            <MyDocument
              materialMetadata={metadata.materialMetadata}
              objectMetadata={metadata.objectMetadata}
              setTotalPages={setTotalPages}
              dictionaryDataState={dictionaryDataState}
              materialId={materialID}
              materialTitle={materialTitle}
              footerText={oasisFooterText}
              oasisHeadingsWhatIsResearchAbout={
                oasisHeadingsWhatIsResearchAbout
              }
              oasisHeadingsWhatReseachersDid={oasisHeadingsWhatReseachersDid}
              oasisHeadingsWhatTheResearchersFound={
                oasisHeadingsWhatTheResearchersFound
              }
              oasisHeadingsThingsToConsider={oasisHeadingsThingsToConsider}
            />
          </PDFViewer>
        </div>
      ) : (
        <LoadingIcon textBeforeIcon="Creating PDF" />
      )}

      {warning ? (
        <div
          className="mb-5"
          style={{
            display: `block`,
            backgroundColor: `#f2a600`,
            padding: `10px`,
            color: `white`,
          }}
        >
          {warning}
        </div>
      ) : null}
    </>
  );
}
